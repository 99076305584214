import color from '@/core/features/styles/color';

type Direction = 'horizontal' | 'vertical';

type DividerColor = keyof typeof dividerColors;

export interface DividerProps {
    className?: string;
    color?: DividerColor;
    direction?: Direction;
    height?: number;
    /** a set value for margin will always
     * override marginTop and marginBottom */
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    width?: number;
}

export default function Divider({
    className,
    color: dividerColor = 'silver',
    direction = 'horizontal',
    height = 30,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    width = 1,
}: DividerProps) {
    if (direction === 'horizontal') {
        return (
            <div
                className={className}
                style={{
                    ...{ borderBottom: `${width}px solid ${color(dividerColor)}` },
                    ...{ marginBottom, marginLeft, marginRight, marginTop },
                }}
            />
        );
    }

    return (
        <div
            className={className}
            style={{
                ...{ borderRight: `${width}px solid ${color(dividerColor)}` },
                ...{ marginBottom, marginLeft, marginRight, marginTop },
                ...(height ? { height } : {}),
            }}
        />
    );
}

const dividerColors = {
    alto: color('alto'),
    gallery: color('gallery'),
    silver: color('silver'),
};
