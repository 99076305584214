import type { BadgeType, BadgeWithTooltipType } from '@/core/components/badges/badge-type';

import React from 'react';

export type BadgeProps = {
    badge: BadgeType | BadgeWithTooltipType;
    borderRadius?: number;
    cursor?: 'cursor-default' | 'pointer';
    fontSize?: number;
    height?: number;
    onClick?: (event: React.MouseEvent<HTMLDivElement>, name: string) => void;
    padding?: 'padding-0' | 'padding-4' | 'padding-x-15';
    qaId?: string;
};

export default function Badge({
    badge,
    borderRadius = 2,
    cursor = 'cursor-default',
    fontSize = 12,
    height,
    onClick,
    padding = 'padding-4',
    qaId,
}: BadgeProps) {
    return (
        <div
            className={`flex-center max-width-100 border-radius-2 ${cursor}`}
            data-qa-id={qaId}
            key={badge.htmlText}
            onClick={(event) => onClick?.(event, badge.htmlText)}
            style={{
                backgroundColor: badge.backgroundColor ? badge.backgroundColor : undefined,
                border: badge.outlineColor ? `1px solid ${badge.outlineColor}` : undefined,
                borderRadius,
                color: badge.textColor,
                height: height ? `${height}px` : '20px',
            }}
        >
            <span
                className={`ellipsis ${padding}`}
                style={{ fontSize: fontSize ? `${fontSize}px` : undefined }}
            >
                {badge.htmlText}
            </span>
        </div>
    );
}
