import React from 'react';

import debounce from 'lodash.debounce';

import { IS_SERVER } from '@/constants/env';

// setting hard-coded initial values to prevent SSR errors
export default function useWindowResize() {
    const [orientation, setOrientation] = React.useState<'landscape' | 'portrait'>('portrait');
    const [windowSize, setWindowSize] = React.useState({ innerHeight: 0, innerWidth: 0 });

    const debouncedEventHandler = () => {
        return debounce(() => {
            setWindowSize(getWindowSize());
            setOrientation(getOrientation());
        }, 100);
    };

    React.useEffect(() => {
        setOrientation(getOrientation());
        setWindowSize(getWindowSize());
        window.addEventListener('resize', debouncedEventHandler());
        return () => {
            window.removeEventListener('resize', debouncedEventHandler());
        };
    }, []);

    return { orientation, windowSize };
}

const getWindowSize = () => {
    if (IS_SERVER) {
        throw 'getWindowSize can not be used server side';
    }
    const { innerHeight, innerWidth } = window;
    return { innerHeight, innerWidth };
};

const getOrientation = () => {
    if (IS_SERVER) {
        throw 'getOrientation can not be used server side';
    }
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
};
